<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Администратор</div>
                <div class="page__desc">Информация об администраторе</div>
            </div>
        </div>

        <div class="page__content">
            <form
                    v-if="admin !== null && stores !== null"
                    class="form-group"
                    method="POST"
                    action=""
                    @submit.prevent="update()">

                <label for="email">Email</label>
                <div class="control-group">
                    <input
                            v-model="admin.email"
                            id="email"
                            type="email"
                            name="email"
                            class="form-control"
                            required>
                </div>
                <br>

                <label for="password">Новый пароль</label>
                <div class="control-group">
                    <input
                            v-model="admin.password"
                            id="password"
                            type="password"
                            name="password"
                            class="form-control"
                            placeholder="********"
                            autocomplete="new-password">
                </div>
                <br>

                <label for="role">Роль</label>
                <div class="control-group">
                    <select
                            v-model="admin.role"
                            id="role"
                            name="role"
                            class="form-control"
                            required>

                        <option value="owner">owner</option>
                        <option value="manager">manager</option>
                        <option value="support">support</option>
                        <option value="courier">courier</option>
                        <option value="collector">collector</option>
                        <option value="collector">supplier</option>
                    </select>
                </div>
                <br>

                <label for="store_id">Магазин</label>
                <div class="control-group">
                    <select
                            v-model="admin.store_id"
                            id="store_id"
                            name="store_id"
                            class="form-control"
                            required>

                        <option value="0">---</option>
                        <option v-for="store in stores" :value="store.id">{{ store.address }}</option>
                    </select>
                </div>
                <br>

                <label for="is_deleted"><input type="checkbox" id="is_deleted" name="is_deleted" v-model="admin.is_deleted"> Заблокирован?</label>
                <br><br>


                <input type="submit" class="btn btn-success btn-large" value="Сохранить">

            </form>
        </div>

    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'AdminsUpdate',
        computed: {
            admin() {
                return this.$store.state.admins.adminGet
            },
            stores() {
                return this.$store.state.stores.stores
            }
        },
        methods: {
            ...mapActions([
                'adminsGet',
                'adminsUpdate',
                'storeStores'
            ]),
            async getAdmin() {
                await this.adminsGet({
                    id: this.$route.params.id,
                })
            },
            async getStores() {
                await this.storeStores()
            },
            async update() {
                const formData = new FormData();
                formData.append("id", this.admin.id);
                formData.append("email", this.admin.email);
                formData.append("role",this.admin.role);
                formData.append("store_id",this.admin.store_id);
                formData.append("is_deleted",this.admin.is_deleted);

                await this.adminsUpdate(formData)
            }
        },
        created() {
            this.getStores();
            this.getAdmin();
        }
    }
</script>